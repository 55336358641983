import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import myConfiguredSanityClient from "../../sanityClient"
import imageUrlBuilder from "@sanity/image-url"

// import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import AniLink from "gatsby-plugin-transition-link/AniLink"
import BlockContent from "@sanity/block-content-to-react"

import serializers from "../components/serializers"

import Icon from "../assets/sis_circle.svg"

import { MdFileDownload, MdInsertDriveFile } from "react-icons/md"

const builder = imageUrlBuilder(myConfiguredSanityClient)

const About = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      sanityAbout {
        _rawBlockOfContent
        title
        description
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
            url
          }
          hotspot {
            x
            y
          }
        }
      }
      sanityEmployees(_id: { eq: "employees" }) {
        employee {
          position
          name
          bio
          image {
            asset {
              url
            }
            hotspot {
              x
              y
            }
          }
        }
      }
      sanityRules {
        fileArray {
          asset {
            url
            originalFilename
          }
        }
      }
      sanitySummaries {
        fileArray {
          asset {
            url
            originalFilename
          }
        }
      }
    }
  `)

  function introImageHasLoaded(e) {
    e.target.classList.add("showImage")
  }

  function getFocalPointsX(member) {
    let xValue = 0.5

    if (member.image.hotspot !== null) {
      xValue = member.image.hotspot.x
    }

    return xValue
  }

  function getFocalPointsY(member) {
    let yValue = 0.5

    if (member.image.hotspot !== null) {
      yValue = member.image.hotspot.y
    }

    return yValue
  }

  function urlFor(source) {
    return builder.image(source)
  }
  return (
    <Layout>
      <SEO title="Om os" description={data.sanityAbout.description} />

      <section className="about">
        <div className="introSection">
          <div className="introWrapper">
            <div className="imageWrapper">
              <img
                className="image"
                onLoad={introImageHasLoaded}
                src={urlFor(data.sanityAbout.image.asset.url)
                  .focalPoint(
                    getFocalPointsX(data.sanityAbout),
                    getFocalPointsY(data.sanityAbout)
                  )
                  .width(500)
                  .height(500)
                  .fit("crop")
                  .auto("format")
                  .url(data.sanityAbout.image.asset.fluid.src)}
                alt={data.sanityAbout.title}
              />{" "}
              <Icon className="fadeinSVG" />
            </div>
            <div className="introText">
              <h1 className="titleFadeIn">{data.sanityAbout.title}</h1>
              <p className="descriptionFadeIn">
                {data.sanityAbout.description}
              </p>
              <div className="whiteSpace"></div>
            </div>
          </div>
        </div>

        <div className="employeeSection">
          <h2>Bestyrelse</h2>
          {data.sanityEmployees.employee.map(function (member) {
            return (
              <div className="employee">
                <img
                  src={urlFor(member.image.asset.url)
                    .focalPoint(
                      getFocalPointsX(member),
                      getFocalPointsY(member)
                    )
                    .width(200)
                    .height(200)
                    .fit("crop")
                    .auto("format")
                    .url(member.image.asset.url)}
                  alt={member.name}
                />
                <div className="textWrapper">
                  <p className="position">{member.position}</p>
                  <h3>{member.name}</h3>
                  <p className="bio">{member.bio}</p>
                </div>
              </div>
            )
          })}
        </div>

        <div className="contentSection">
          <BlockContent
            blocks={data.sanityAbout._rawBlockOfContent}
            projectId="q2jj4zmh"
            dataset="production"
            serializers={serializers}
          />
        </div>

        <div className="ruleSection">
          <h2>Vedtægter</h2>
          {data.sanityRules.fileArray.map(function (file) {
            return (
              <a href={file.asset.url}>
                <div className="file">
                  <MdInsertDriveFile />
                  <p>{file.asset.originalFilename}</p>
                  <MdFileDownload />
                </div>
              </a>
            )
          })}
        </div>

        <div className="ruleSection">
          <h2>Referater</h2>
          {data.sanitySummaries.fileArray.map(function (file) {
            return (
              <a href={file.asset.url}>
                <div className="file">
                  <MdInsertDriveFile />
                  <p>{file.asset.originalFilename}</p>
                  <MdFileDownload />
                </div>
              </a>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export default About
